import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import { ResturantGraphql } from "../../components/restaurantSearch";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import Taggar from "../../components/taggar";
import Bokabord from "../../components//bokabord";
import Searchbar from "../../components/searchbar";
import { ResturangQuery } from "../../components/scripts/graphQlQuerys/restaurantQuery";
import Hero from '../../components/hero'
import * as Global from "../../css/globalStyle";

const Restaurant = () => {
  let [english] = useGlobal("English");
  const pageType = "Restaurang Alla";
  let heroData = getLang(english, ResturangQuery().allContentfulRestaurangSida);
  let { seoTitel, seoDescription, Synligataggar, node_locale, introText } = heroData[0].node;
  const [SearchKeyword, setSearchKeyword] = useState(" ");

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "Restaurant", newURL);
    }
  });

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />

      {heroData[0].node.heroBild.file.contentType === 'video/mp4' ? (
        <a className="textDecorationNone" >
          <Global.BackgroundVideo>
            <video loop autoPlay muted playsInline>
              <source src={heroData[0].node.heroBild.file.url} type="video/mp4" />
            </video>
          </Global.BackgroundVideo>
        </a>
      ) : (
        <Hero introText={introText} heroTema={null} data={heroData} img={'img1'} />
      )}

      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} /> 
      <Taggar mainPage="restaurant" subPage="Alla" data={Synligataggar} />
      <ResturantGraphql SearchKeyword={SearchKeyword} english={english} pageType={pageType} />
      <Bokabord lang={node_locale} />
    </App>
  );
};

export default Restaurant;
