import React from "react";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import { useGlobal } from "reactn";
import * as Styled from "../css/CardFlexStyle";
import TextField from "@material-ui/core/TextField";
import getLang from "./scripts/getLang";
import PictureFilter from "./scripts/pictureFilter";
import CheckForHighlight from "../components/scripts/checkForHightlight";
import {SortBySeason2 } from "./scripts/SortBySeasons";

import { ResturangQuery } from './scripts/graphQlQuerys/restaurantQuery'
import { EnheterQuery } from './scripts/graphQlQuerys/enheterQuery'

export const ResturantGraphql = props => {
  let { SearchKeyword, english, pageType } = props;
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let restaurant = getLang(english, EnheterQuery());
  restaurant = SortBySeason2(restaurant);
  restaurant =  CheckForHighlight(pageType, restaurant)
    .filter(edges => {
      return edges.node.sida.includes("Restaurang");
    })
    .filter(restaurant => {
      let newString = restaurant.node.skord.toString();
      return   restaurant.node.namn.trim() === "Stockholm City Conference Centre" ? null :  newString.match(regex);
    })
    .map((item, index) => {
      let orderOne = "";
      if (item.node.highlight) {
        orderOne = "orderOne";
      }

      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/restaurant/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={PictureFilter('img1', item)}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });
  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{restaurant}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};

export const ResturantRender = ({children, SearchKeyword,setSearchKeyword}) => {
  let [english] = useGlobal("English");
  let filteredData = getLang(english, ResturangQuery().allContentfulRestaurangSida);
  const { introText } = filteredData[0].node;

  return (
    <Styled.Container>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: introText.childContentfulRichText.html
          }}
        />
      </div>
      <Styled.Searchbar id="search">
        <TextField
          id="outlined-search"
          label={english ? "Search" : "Sök"}
          type="search"
          margin="normal"
          variant="outlined"
          fullWidth={true}
          onChange={e => setSearchKeyword(e.target.value)}
          value={SearchKeyword}
        />
      </Styled.Searchbar>
      {children}
    </Styled.Container>
  );
};
